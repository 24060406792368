import { proxy } from 'valtio'

const state = proxy({
  intro: true,
  menus: ['Heavy Defence', 'Mine Protection', 'High Mobility', 'Ballistic Protection'],
  posisis: [1, 2, 3, 4,'home'],
  materials: ['TNI_TEXTURE_00','TNI_TEXTURE_01','TNI_TEXTURE_02','TNI_TEXTURE_03','TNI_TEXTURE_04','TNI_TEXTURE_05'],
  decals: 'w3o',
  posisi:'home',
  material: 0,
  color: '#ccc',
  decal: 'w3o',
})

export { state }
