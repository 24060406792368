import { useRef, useState, Suspense, useEffect } from 'react'
import { Canvas, useFrame, extend, useThree, } from '@react-three/fiber'
import {
    useGLTF, Html, useProgress,
    Environment, OrbitControls,
    useAnimations, ContactShadows, Float,
    Center, AccumulativeShadows, RandomizedLight, CameraControls, PerspectiveCamera
} from '@react-three/drei'
import { FaCircle } from 'react-icons/fa'
import * as THREE from 'three'
import { geometry } from 'maath'
import { useSnapshot } from 'valtio'
import { state } from './store'
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'
import { vec3 } from 'three/examples/jsm/nodes/Nodes.js'
// import { Model as Tankyu } from './TankLeopard02A7_3'

gsap.registerPlugin(useGSAP);
extend(geometry)

function App({ fov = 70 }) {
    const [loading, setLoading] = useState(true);
    const spinner = document.getElementById("spinner");
    if (spinner) {
        setTimeout(() => {
            spinner.style.display = "none";
            setLoading(false);
        }, 1000);
    }
    return (
        !loading &&
            <Canvas
                shadows
                camera={{ fov }}
                gl={{
                    preserveDrawingBuffer: true,
                    shadowMap: false,
                    toneMapping: 0,
                    // toneMappingExposure: 1,
                    // useLegacyLights: false,
                    // toneMapping: THREE.NoToneMapping,
                }}
                eventSource={document.getElementById('root')} eventPrefix="client">

                <Suspense fallback={<Loader />}>
                    <PerspectiveCamera makeDefault position={[0, 15, 15]} />
                    <fog attach="fog" args={['black', 2, 35]} />
                    {/* <spotLight intensity={2.5} angle={0.1} penumbra={1} position={[10, 15, 10]} /> */}
                    <pointLight color="black" decay={10} />
                    <ambientLight intensity={3.2} color="white" />
                    {/* <pointLight position={[10, 10, -5]} color="green" /> */}
                    {/* <pointLight position={[-10, -10, 5]} color="black" intensity={0.3} /> */}
                    {/* apartment, city, dawn, forest, lobby, night, park, studio, sunset, warehouse */}
                    <Environment preset="night" />
                    {/* <Float
          rotation-x={-Math.PI / 4}
          floatIntensity={1}
          speed={2}
          rotationIntensity={2}
        > */}
                    <Tank position={[0, -2, 0]} rotation-y={Math.PI / 3} />
                    <ContactShadows position={[0, -1.95, 0]} opacity={0.75} scale={15} blur={3.5} far={20} />
                    {/* <CameraControls /> */}
                </Suspense>
            </Canvas>
    )
};
function Loader() {
    const { progress, active } = useProgress()
    // {progress.toFixed(1)}% Loaded
    return <Html center>
        <div className="custom-loader" style={{ color: 'white' }}><br />
            Load: {progress.toFixed(0)}%
        </div>
    </Html>
}
function Note({ time, ...props }) {
    // console.log(props)
    return (
        <>
            <group>
                <mesh {...props}>
                    <Marker posisi={props.data}>
                        <FaCircle style={{ color: 'orange' }} size="1.0em" />
                    </Marker>
                </mesh >
            </group>
        </>
    )
}
function Marker({ children, ...props }) {
    const group = useRef()
    const snap = useSnapshot(state)
    // This holds the local occluded state
    const [isOccluded, setOccluded] = useState()
    const [isInRange, setInRange] = useState()
    // const isVisible = true
    const isVisible = isInRange && !isOccluded && (snap.posisi === 'home')
    // console.log(isVisible)
    // && (snap.posisi === props.posisi || snap.posisi === 'home')
    // Test distance
    const vec = new THREE.Vector3()
    useFrame((state) => {
        const range = state.camera.position.distanceTo(group.current.getWorldPosition(vec)) <= 30
        if (range !== isInRange) setInRange(range)
    })
    return (
        <group ref={group}>
            <Html
                // 3D-transform contents
                // transform
                // Hide contents "behind" other meshes
                occlude
                className='on'
                // Tells us when contents are occluded (or not)
                onOcclude={setOccluded}
                // We just interpolate the visible state into css opacity and transforms
                style={{ transition: 'all 0.2s', opacity: isVisible ? 1 : 0, transform: `scale(${isVisible ? 0.8 : 0.25})` }}

                {...props}>

                <div onClick={() => (
                    state.posisi = props.posisi
                )
                }>
                    {children}
                </div>
            </Html>
        </group>
    )
}
function Tank(props) {
    const group = useRef()
    const gerakKepala = useRef({ position: { x: 0, y: 0, z: 0 } });
    const controls = useRef({ position: { x: 0, y: 0, z: 0 } });
    const tl = useRef();
    const { camera } = useThree();
    const viewport = useThree((state) => state.viewport);
    const snap = useSnapshot(state)
    const [isClick, setIsClick] = useState(false)

    const shadowBias = -0.005;
    const shadowMapSize = 2048;

    const resizeTank = Math.min(Math.max(window.innerWidth / 1300, 0.9), 1.2);
    const tankPosition = window.innerWidth / 1300;
    const isMobile = window.innerWidth < 768;

    const { nodes, materials, animations } = useGLTF('./TankLeopard02A7.glb')
    const { actions } = useAnimations(animations, group)
    // actions.clampWhenFinished = true;
    const material = [
        {
            kepala: materials.TNI_TEXTURE_00,
            tank: materials.TNI_TEXTURE_00,
            canon: materials.Cannon_00,
        },
        {
            kepala: materials.TNI_TEXTURE_01,
            tank: materials.TNI_TEXTURE_01,
            canon: materials.Cannon_01,
        },
        {
            kepala: materials.TNI_TEXTURE_02,
            tank: materials.TNI_TEXTURE_02,
            canon: materials.Cannon_02,
        },
        {
            kepala: materials.TNI_TEXTURE_03,
            tank: materials.TNI_TEXTURE_03,
            canon: materials.Cannon_03,
        },
        {
            kepala: materials.TNI_TEXTURE_04,
            tank: materials.TNI_TEXTURE_04,
            canon: materials.Cannon_04,
        },
    ]
    const index = snap.material;
    // console.log(gerakKepala)
    const fitCamera = async () => {
        const lastPos = camera.position;
        controls.current.smoothTime = 1.6;
        tl.current = gsap
            .timeline()
            .to(lastPos, {
                x: 0, y: 2, z: 9, duration: 2, delay: 0.5,
                ease: "power1.inOut",
            })
        // camera.lookAt([meshFitCameraHome.current.position.x,meshFitCameraHome.current.position.y,12])
        // // controls.current.setPosition(meshFitCameraHome.current.position, true);
        // // controls.current.camera.position.setPosition(camera.position, true)
    };
    useEffect(() => {
        window.addEventListener("resize", fitCamera);
        return () => window.removeEventListener("resize", fitCamera);
    }, []);

    const toggleTimeline = (pos) => {
        const lastPos = camera.position;
        switch (pos) {
            case 1:
                return (
                    tl.current = gsap
                        .timeline()
                        .to(lastPos, {
                            x: -2, y: 1, z: 10, duration: 2, delay: 0.5,
                            ease: "power1.inOut",
                        }),
                    tl.current = gsap
                        .timeline().to(gerakKepala?.current.rotation, {
                            x: 0, y: 0, z: 0, duration: 2, delay: 0,
                            ease: "power1.inOut",
                        }),
                    tl.current = gsap
                        .timeline().to(controls?.current.target, {
                            x: -1, y: -0.5, z: 0, duration: 2, delay: 0,
                            ease: "power1.inOut",
                        })

                )
            case 2:
                return tl.current = gsap
                    .timeline()
                    .to(lastPos,
                        {
                            x: -5, y: -1, z: 10, duration: 2, delay: 0.5,
                            ease: "power1.inOut",
                        }),
                    tl.current = gsap
                        .timeline().to(gerakKepala?.current.rotation, {
                            x: 0, y: 0, z: 0, duration: 2, delay: 0,
                            ease: "power1.inOut",
                        }),
                    tl.current = gsap
                        .timeline().to(controls?.current.target, {
                            x: 0, y: -1, z: 0, duration: 2, delay: 0,
                            ease: "power1.inOut",
                        })

            case 3:
                return tl.current = gsap
                    .timeline()
                    .to(lastPos,
                        {
                            x: 10, y: 0, z: 12, duration: 2, delay: 0.5,
                            ease: "power1.inOut",
                        }),
                    tl.current = gsap
                        .timeline().to(gerakKepala?.current.rotation, {
                            x: 0, y: 1.485, z: 0, duration: 2, delay: 0,
                            ease: "power1.inOut",
                        }),
                    tl.current = gsap
                        .timeline().to(controls?.current.target, {
                            x: 0, y: 0, z: 0, duration: 2, delay: 0,
                            ease: "power1.inOut",
                        })
            case 4:
                return tl.current = gsap
                    .timeline()
                    .to(lastPos,
                        {
                            x: 3, y: -2, z: 5, duration: 2, delay: 0.5,
                            ease: "power1.inOut",
                        }),
                    tl.current = gsap
                        .timeline().to(gerakKepala?.current.rotation, {
                            x: 0, y: 0, z: 0, duration: 2, delay: 0,
                            ease: "power1.inOut",
                        }),
                    tl.current = gsap
                        .timeline().to(controls?.current.target, {
                            x: 0, y: 0, z: 0, duration: 2, delay: 0,
                            ease: "power1.inOut",
                        })
            default:
                return tl.current = gsap
                    .timeline()
                    .to(lastPos,
                        {
                            x: 0, y: 2, z: 12, duration: 2, delay: 0.5,
                            ease: "power1.inOut",
                            onUpdate: function () {
                                camera.lookAt(0, 0, 0)
                            }
                        }),
                    tl.current = gsap
                        .timeline().to(gerakKepala?.current.rotation, {
                            x: 0, y: 0, z: 0, duration: 2, delay: 0,
                            ease: "power1.inOut",
                        }),
                    tl.current = gsap
                        .timeline().to(controls?.current.target, {
                            x: 0, y: 0, z: 0, duration: 2, delay: 0,
                            ease: "power1.inOut",
                        })
        }
    };
    toggleTimeline(snap.posisi)

    useFrame((state, delta) => {
        actions?.lampugerak.play()
    })

    return (
        <>

            <OrbitControls
                ref={controls}
                minDistance={5}
                maxDistance={12}
                minPolarAngle={0}
                maxPolarAngle={Math.PI / 2}
                enablePan={false}
                // enableZoom={!isMobile}
                // enableRotate={!isMobile}
            />

            <group ref={group} {...props} dispose={null}>

                <group name="Scene">
                    <group name="tank"
                        scale={resizeTank}
                    // position-x={isMobile ? 2 : 1.2 * tankPosition}
                    >
                        <Note
                            position={[-2.666, 2.521, -0.006]}
                            data={1}
                        />
                        <Note
                            position={[-3.666, 0.521, 0.006]}
                            data={2}
                        />
                        <Note
                            position={[-0.451, 0.583, 2.30]}
                            data={3}
                        />
                        <Note
                            position={[-0.756, 2.689, 1.696]}
                            data={4}
                        />
                        {
                            index === 5 ? (
                                <group name='TankKitty' scale={0.9}>
                                    <group ref={gerakKepala} name="GerakKepala" position={[-0.46, 1.752, 0]} rotation={[0, 0.001, 0]}>
                                        <group name="kepala" position={[-0.552, -1.752, 0]} rotation={[0, -1.571, 0]} scale={0.011}>
                                            <mesh name="kepala_1" geometry={nodes.kepala_1.geometry} material={materials.TNI_TEXTURE_07} />
                                            <mesh name="kepala_2" geometry={nodes.kepala_2.geometry} material={materials.Cannon_05} />
                                            <mesh name="kepala_3" geometry={nodes.kepala_3.geometry} material={materials.Glass_16} />
                                        </group>
                                    </group>
                                    <group name="badanTank" position={[-0.989, -0.05, 0.032]} rotation={[0, -1.571, 0]}>
                                        <mesh name="Body_Tank" geometry={nodes.Body_Tank.geometry} material={materials.TNI_TEXTURE_05} />
                                        <mesh name="Body_Tank_1" geometry={nodes.Body_Tank_1.geometry} material={materials.Glass_14} />
                                        <mesh name="Body_Tank_2" geometry={nodes.Body_Tank_2.geometry} material={materials.Glass_15} />
                                        <mesh name="Body_Tank_3" geometry={nodes.Body_Tank_3.geometry} material={materials.Wheels_01} />
                                        <mesh name="Body_Tank_4" geometry={nodes.Body_Tank_4.geometry} material={materials.Glass_16} />
                                    </group>
                                </group>
                            ) : (
                                <group name='TankCostum' scale={0.9}>
                                    <group ref={gerakKepala} name="GerakKepala" position={[-0.46, 1.752, 0]} rotation={[0, 0.001, 0]}>
                                        <group name="kepala" position={[-0.552, -1.752, 0]} rotation={[0, -1.571, 0]} scale={0.011}>
                                            <mesh name="kepala_1" geometry={nodes.kepala_1.geometry} material={material[index].kepala} />
                                            <mesh name="kepala_2" geometry={nodes.kepala_2.geometry} material={material[index].canon} />
                                            <mesh name="kepala_3" geometry={nodes.kepala_3.geometry} material={materials.Glass_16} />
                                        </group>
                                    </group>
                                    <group name="badanTank" position={[-0.989, -0.05, 0.032]} rotation={[0, -1.571, 0]}>
                                        <mesh name="Body_Tank" geometry={nodes.Body_Tank.geometry} material={material[index].tank} />
                                        <mesh name="Body_Tank_1" geometry={nodes.Body_Tank_1.geometry} material={materials.Glass_14} />
                                        <mesh name="Body_Tank_2" geometry={nodes.Body_Tank_2.geometry} material={materials.Glass_15} />
                                        <mesh name="Body_Tank_3" geometry={nodes.Body_Tank_3.geometry} material={materials.Wheels_01} />
                                        <mesh name="Body_Tank_4" geometry={nodes.Body_Tank_4.geometry} material={materials.Glass_16} />
                                    </group>
                                </group>
                            )
                        }
                        <group scale={0.9}>
                            <group name="RantaiRoda" position={[-1.139, 0.06, -1.567]} rotation={[Math.PI, 0, -0.001]} scale={0.004}>
                                <mesh name="Rantai" geometry={nodes.Rantai.geometry} material={materials.RantaiRoda_01} />
                                <mesh name="Rantai_1" geometry={nodes.Rantai_1.geometry} material={materials.RantaiRoda_02} />
                            </group>
                        </group>
                        {/* <lineSegments name="pathRoda" geometry={nodes.pathRoda.geometry} material={nodes.pathRoda.material} position={[-1.139, 0.06, -1.567]} /> */}
                    </group>
                    <group name="lampugerak" position={[1.623, 10.452, -11.213]} rotation={[-2.043, -0.052, 0.109]}>
                        <directionalLight intensity={1.029} decay={2} position={[5.762, 7.659, 3.481]} rotation={[-0.34, 0.264, -2.591]} shadow-bias={shadowBias} shadow-mapSize-width={shadowMapSize} shadow-mapSize-height={shadowMapSize} />
                    </group>
                    <directionalLight intensity={2.015} decay={2} position={[-2.089, 16.704, -12.312]} rotation={[-1.9, 0.071, -1.674]} />

                    <mesh name="Doom" geometry={nodes.Doom.geometry} material={materials.tembok} />
                    <group name="Doom_02" position={[-0.106, -0.141, 0.26]} scale={148.257}>
                        <mesh name="Doom_02_1" geometry={nodes.Doom_02_1.geometry} material={materials.lantai} />
                        <mesh name="Doom_02_2" geometry={nodes.Doom_02_2.geometry} material={materials.tembok} />
                    </group>
                </group>
            </group>
        </>
    )
}

useGLTF.preload('./TankLeopard02A7.glb')

export default App;