import { createRoot } from 'react-dom/client'
import './styles.css'
import React from "react";
import App from './CanvasTank'
import { Overlay } from './Overlay'

createRoot(document.getElementById('root')).render(
  <>
    <React.StrictMode>
      <App />
      <Overlay />
    </React.StrictMode>,
  </>
)
