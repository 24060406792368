import { motion, AnimatePresence } from 'framer-motion'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useSnapshot } from 'valtio'
import { state } from './store'

export default function CardNote() {
    const snap = useSnapshot(state)
    const transition = { type: 'spring', duration: 0.8 }
    const config = {
        initial: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } },
        animate: { x: 0, opacity: 1, transition: { ...transition, delay: 0.5 } },
        exit: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } }
    }
    return (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <AnimatePresence>
                {snap.posisi === 'home' ? (
                    <></>
                ) : (
                    <motion.section style={{opacity:0}} key={snap.posisi} {...config}>
                        <CardContent snap={snap.posisi} />
                    </motion.section>
                )
                }
            </AnimatePresence>
        </div>
    )
}


function CardContent(props) {
    const datas = [
        {
            id: 1,
            title: 'Heavy Defence',
            content: 'The 120mm main gun (calibre length 55) together with the improved optronics make for the exceptional combat capabilities of the Leopard 2.',
            videoUrl: './vid_Heavydefence.mp4'
        }, {
            id: 2,
            title: 'Mine Protection',
            content: 'The hull area has been additionally reinforced in order to better protect the crew from mines, IEDs and other threats.',
            videoUrl: './vid_MineProtection.mp4'
        }, {
            id: 3,
            title: 'High Mobility',
            content: 'The running gear and power pack have been optimised and reinforced for better stability and ideal power transmission in comparison with earlier variants.',
            videoUrl: './vid_HighMobility.mp4'
        }, {
            id: 4,
            title: 'Ballistic Protection',
            content: 'The turret crew has extra protection for greater safety from threats from above.',
            videoUrl: './vid_BallisticProtection.mp4'
        }];
    if (props.snap == 'home') {
        return (<></>);
    } else {
        const index = props.snap - 1;
        return (
            <div>
                <div className='popup'>
                    <div style={{ borderRadius: '25px', padding: '5px 5px', width: '335px', height: '310px' }} className='popup-bg'>
                        <div className='closeBtn' onClick={() => (state.posisi = 'home')}>
                            <AiOutlineCloseCircle />
                        </div>
                        <div style={{ height: '180px', overflow: 'hidden' }}>
                            <video width="100%" controls controlsList='nodownload' disablePictureInPicture playsInline muted autoPlay loop style={{ borderRadius: '20px 20px 0px 0px' }}>
                                <source src={datas[index].videoUrl} type="video/mp4" />
                            </video>
                            {/* <img src='./tank.png' style={{ borderRadius: '25px 25px 0px 0px', width: '100%', objectFit: 'cover' }} /> */}
                        </div>
                        <div>
                            <div style={{ padding: '10px 10px', color: 'white', display: 'flex', flexDirection:'column', alignContent: 'center',gap:'5px' }}>
                                <span style={{ fontSize: 15, fontWeight: 'bold' }}>{datas[index].title}</span>
                                <span style={{ fontSize: 14 }}>
                                    {datas[index].content}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}