import { motion, AnimatePresence } from 'framer-motion'
import { AiOutlineArrowLeft, AiFillHome, AiOutlineFullscreen, AiOutlineFullscreenExit, AiFillUpCircle, AiFillDownCircle } from 'react-icons/ai'
import { useSnapshot } from 'valtio'
import { state } from './store'
import CardNote from './Card'
import {
  useProgress,
} from '@react-three/drei'
import { useState, useEffect } from 'react'


export function Overlay() {
  const { progress, active } = useProgress()
  // console.log(progress.toFixed(0))
  const load = progress.toFixed(0);
  const transition = { type: 'spring', duration: 1.8 }
  const config = {
    initial: { x: 800, opacity: 0, transition: { ...transition, delay: 0 } },
    animate: { x: 0, opacity: 1, transition: { ...transition, delay: 1 } },
    exit: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } }
  }
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
      <AnimatePresence>
        {load === '100' && <motion.section style={{ opacity: 0 }} key="custom" {...config}>
          <CardNote />
          <Customizer />
        </motion.section>
        }
      </AnimatePresence>
    </div>
  )
}

function Customizer() {
  const snap = useSnapshot(state)
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isCredit, setIsCredit] = useState(false);
  const toggleCredit = () => {
    setIsCredit(!isCredit);
  }
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Masuk ke fullscreen
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) { // Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari, Opera
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
        document.documentElement.msRequestFullscreen();
      }
    } else {
      // Keluar dari fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen); // Ubah status fullscreen
  };
  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement != null);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
    };
  }, []);
  return (<>
    <div className='logoMobile'>
      <img src='./logo.png' width='100%' />
    </div>
    {/* Start Mobile UI */}
    <div className="wrap-menu-mobile">
      <div className='ui-row'>
        <div className="ui-home">
          {snap.posisi === 'home' ?
            <button disabled>
              <AiFillHome />
            </button>
            :
            <button onClick={() => (state.posisi = 'home')}>
              <AiOutlineArrowLeft />
            </button>
          }
        </div>

        <div className="ui-menu">
          {snap.menus.map((pos, index) => (
            <button key={pos} className={snap.posisi === snap.posisis[index] ? 'active' : ''} onClick={() => (state.posisi = snap.posisis[index])}>
              {pos}
            </button>
          ))}
        </div>
      </div>

      <div className="ui-material">
        {snap.materials.map((material, index) => (
          <div key={material} className={`square`} style={{ backgroundImage: `url(${material}.png)` }} onClick={() => (state.material = index)}></div>
        ))}
      </div>

      <div className="ui-credit" style={{ color: 'white', fontSize: 'smaller' }}>
        <div className='credit-btn' onClick={toggleCredit}>Credits {isCredit ? <AiFillDownCircle /> : <AiFillUpCircle />}</div>
        {isCredit ? <>
          <div className='credit-item'>Modelling by <a href='https://sketchfab.com' target='_blank'>sketchfab.com</a></div>
          <div className='credit-item'>Videos by <a href='https://www.knds.de' target='_blank'>www.knds.de</a></div>
          <div className='credit-item'>All materials are used for demonstration purposes only. No, we don't sell Hello Kitty tank <span className='emoti'>😁✌🏼</span></div>
        </> : <></>}
      </div>
    </div>
    {/* Start Desktop UI */}
    <div className="wrap-menu">

      <div className='logo'>
        <img src='./logo.png' />
      </div>

      <div className="ui-home">
        {snap.posisi === 'home' ?
          <button disabled>
            <AiFillHome />
          </button>
          :
          <button onClick={() => (state.posisi = 'home')}>
            <AiOutlineArrowLeft />
          </button>
        }
      </div>

      <div className="ui-menu">
        {snap.menus.map((pos, index) => (
          <button key={pos} className={snap.posisi === snap.posisis[index] ? 'active' : ''} onClick={() => (state.posisi = snap.posisis[index])}>
            {pos}
          </button>
        ))}
      </div>

      <div className="ui-material">
        {snap.materials.map((material, index) => (
          <div key={material} className={`square`} style={{ backgroundImage: `url(${material}.png)` }} onClick={() => (state.material = index)}></div>
        ))}
      </div>


      <div className="ui-credit" style={{ color: 'white', fontSize: 'smaller' }}>
        <div className='credit-btn' onClick={toggleCredit}>Credits {isCredit ? <AiFillDownCircle /> : <AiFillUpCircle />}</div>
        {isCredit ? <>
          <div className='credit-item'>Modelling by <a href='https://sketchfab.com' target='_blank'>sketchfab.com</a></div>
          <div className='credit-item'>Videos by <a href='https://www.knds.de' target='_blank'>www.knds.de</a></div>
          <div className='credit-item'>All materials are used for demonstration purposes only. No, we don't sell Hello Kitty tank <span className='emoti'>😁✌🏼</span></div>
        </> : <></>}
      </div>

    </div>
    <div className='wrap-fullscreen' style={{ color: 'white' }} >
      <div className='ui-fullscreen-btn' onClick={toggleFullScreen}>
        {isFullScreen ? <AiOutlineFullscreenExit /> : <AiOutlineFullscreen />}
      </div>
      <span>{isFullScreen ? 'Exit Fullscreen' : 'Open Fullscreen'}</span>
    </div>
    {/* End Desktop UI */}
  </>)
}
